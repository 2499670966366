const styles = {
  global: {
    "html, body": {
      bg: "black",
      color: "white",
      fontSize: "lg",
      fontFamily: "Helvetica, sans-serif",
    },
  },
}

export default styles
